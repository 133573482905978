import * as React from "react";
import gatsby from "../images/gatsby.png";
import police from "../images/police.png";

const links = [
  { url: "https://twitter.com/wasdjkl_", label: "Twitter" },
  { url: "https://github.com/wasdjkl", label: "Github" },
  { url: "/rss.xml", label: "RSS" },
];

const rightLinks = [
  { url: "https://www.gatsbyjs.org/", label: "Gatsby", icon: gatsby },
  {
    url: "https://beian.miit.gov.cn",
    label: "辽ICP备19013759号",
    icon: police,
  },
];

const FooterLink = ({ link }): JSX.Element => {
  return (
    <a
      className="flex items-center text-sm text-gray-600 px-2 hover:cursor-pointer hover:text-gray-900 hover:bg-gray-200 leading-[28px] dark:hover:bg-gray-700 dark:hover:text-gray-200"
      href={link.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {link.icon && (
        <img width="20px" height="20px"
          className="inline-block h-[20px]"
          src={link.icon}
          alt={link.label}
        />
      )}
      <span className="px-0.5">{link.label}</span>
    </a>
  );
};

const Footer = (): JSX.Element => {
  return (
    <footer className="sm:fixed bottom-0 left-0 w-full bg-gray-100 dark:bg-gray-900 z-40 min-h-[28px]">
      <section className="flex items-center sm:justify-between justify-center h-full mx-auto max-w-[1280px] flex-wrap sm:flex-nowrap">
        <nav className="flex gap-1.5 mx-4">
          <span className="h-[28px] text-gray-600">
            Made with <span className="text-red-500">❤</span>
          </span>
          {links.map((link) => (
            <FooterLink link={link} key={link.url} />
          ))}
        </nav>
        <nav className="flex gap-1.5 mx-4">
          {rightLinks.map((link) => (
            <FooterLink link={link} key={link.url} />
          ))}
        </nav>
      </section>
    </footer>
  );
};

export default Footer;
