import * as React from "react";
import { useState } from "react";

function setDarkTheme(setTheme) {
  localStorage.setItem("theme", "dark");
  setTheme("dark");
  document.documentElement.classList.add("dark");
}

function setLightTheme(setTheme) {
  localStorage.setItem("theme", "light");
  setTheme("light");
  document.documentElement.classList.remove("dark");
}

const ThemeSwitcher = () => {
  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }

  const [theme, setTheme] = useState(localStorage.theme || "light");

  const onUpdateTheme = () => {
    theme === "light" ? setDarkTheme(setTheme) : setLightTheme(setTheme);
  };

  return (
    <button onClick={onUpdateTheme} className="theme-switcher">
      <span>{theme === "dark" ? "🌙" : "🌞"}</span>
    </button>
  );
};
export default ThemeSwitcher;
