import * as React from "react";
import { Link } from "gatsby";
import ThemeSwitcher from "./ThemeSwitcher";
const isSSR = typeof window === "undefined";
const links = [
  { url: "/posts", label: "Articles" },
  { url: "/projects", label: "Projects" },
  { url: "/software", label: "Software" },
  { url: "/me", label: "About me" },
];

const HeaderLink = ({ link }): JSX.Element => {
  return (
    <Link
      className="flex items-center text-sm text-gray-600 px-2 hover:cursor-pointer hover:text-gray-900 hover:bg-gray-200 leading-[35px] dark:hover:bg-gray-700 dark:hover:text-gray-200"
      to={link.url}
    >
      <span className="px-0.5">{link.label}</span>
    </Link>
  );
};

const Header = (): JSX.Element => {
  return (
    <header className="fixed top-0 left-0 w-full bg-gray-100 dark:bg-gray-900 shadow z-40 h-[35px]">
      <section className="flex items-center justify-between h-full mx-auto max-w-[1280px]">
        <nav>
          <Link
            className="text-gray-900 bg-gray-200 inline-block px-2 hover:cursor-pointer leading-[35px] dark:text-gray-200 dark:bg-gray-700"
            to={"/"}
          >
            wasdjkl
          </Link>
        </nav>

        <div id="toolbar" className="flex gap-1.5 mx-2">
          <nav className="flex gap-1.5 mx-2">
            {links.map((link) => (
              <HeaderLink link={link} key={link.url} />
            ))}
          </nav>
          {/*<Colors />*/}
          {/*<button onClick={onUpdateTheme} className="">*/}
          {/*  <span>{theme === "dark" ? "🌙" : "🌞"}</span>*/}
          {/*</button>*/}
          {/*<nav>*/}
          {/*  <Link*/}
          {/*    className="text-gray-900 bg-gray-200 inline-block px-2 hover:cursor-pointer leading-[35px] dark:text-gray-200 dark:bg-gray-700"*/}
          {/*    to={"/"}*/}
          {/*  >*/}
          {/*    wasdjkl*/}
          {/*  </Link>*/}
          {/*</nav>*/}
          {!isSSR && <ThemeSwitcher />}
        </div>
      </section>
    </header>
  );
};

export default Header;
