import * as React from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function Layout({ children }) {
  return (
    <div className="bg-slate-50 dark:bg-gray-700 duration-150">
      <Header />
      <main className="max-w-[1280px] mx-auto pt-[35px] pb-[28px] px-2 min-h-screen ">
        {children}
      </main>
      <Footer />
    </div>
  );
}
